// @flow

import { NoIndexMetaTag, PageContent, SEO } from "@components";
import { Analytics } from "@containers";
import { Constants } from "@utils";
import { StorageContext } from "@utils/BrowserStorage/StorageContext";
import { CategoryContext } from "@utils/Category";
import { PAGE_TYPES } from "@utils/Constants";
import { contentfulTypenames } from "@utils/ContentfulConstants";
import { LocaleContext } from "@utils/Localization/LocaleContext";
import withURLParameters from "@utils/ParametersHOC";
import { graphql } from "gatsby";
import React from "react";

import { determineStyle } from "../utils";
import { getCategoryBreadcrumb } from "../utils/Breadcrumb";
import {
    CONTENT_GROUP_VALUES,
    LEAD_SCORING_DEFAULT_VALUES,
} from "../utils/Constants";
import { mapCategoryPage } from "../utils/ContentModelMapper";
import { PageContext } from "../utils/DynamicArticles";
import { loadLocalStorageId } from "../utils/LocalStorage";

const PageCategoryTemplate = ({ data, query, location }: *) => {
    const {
        page,
        channel,
        navigation,
        footer,
        market,
        markets: { nodes: markets },
        site: {
            siteMetadata: { siteUrl },
        },
    } = data;

    const breadcrumb = getCategoryBreadcrumb({ ...page, navigation }, market);

    const navData = determineStyle("Default", page.navigation || navigation);

    return (
        <LocaleContext.Provider
            value={{
                language: page.node_locale,
                market,
            }}
        >
            <PageContext.Provider value={page}>
                <StorageContext.Provider
                    value={loadLocalStorageId(Constants.QUERY_PARAMETERS)}
                >
                    <Analytics.PageWrapper
                        key={page.id}
                        entry={page.id}
                        type={PAGE_TYPES.CATEGORY}
                        title={page.title}
                        language={page.node_locale}
                        urlParameters={query}
                        slug={page.slug}
                        segments={page.segments}
                        seoKeywords={page.seoKeywords}
                        leadScoringValue={
                            page.leadScoringValue ||
                            LEAD_SCORING_DEFAULT_VALUES[PAGE_TYPES.CATEGORY]
                        }
                        tags={page.metadata.tags}
                    >
                        <CategoryContext.Provider value={page.slug}>
                            <SEO
                                title={page.title}
                                translations={page.translations}
                                publishedMarkets={page.markets}
                                metaDescription={page.metaDescription}
                                siteUrl={siteUrl}
                                currentPath={location.pathname}
                                page={{ ...page, navigation }}
                                market={market}
                            />
                            <NoIndexMetaTag channel={channel} page={page} />
                            <PageContent
                                navigation={navData}
                                footer={page.footer || footer}
                                translations={page.translations}
                                header={mapCategoryPage({
                                    ...page.entry,
                                    breadcrumb,
                                })}
                                contentAboveEntry={page.contentAboveEntry}
                                entry={{
                                    __typename:
                                        contentfulTypenames.collectionGrid,
                                    contentGroup: CONTENT_GROUP_VALUES.articles,
                                    lunrIndex: page.initialArticles,
                                    maxItemsToDisplay: 9,
                                    displayPagination: true,
                                    applyFilter: {
                                        categories: [
                                            {
                                                label: page.entry.contentful_id,
                                            },
                                        ],
                                    },
                                }}
                                contentBelowEntry={page.contentBelowEntry}
                                publishedMarkets={markets}
                                offer={page.offer}
                                slug={page.slug}
                                enableScrollTop={page.enableScrollTop}
                                navigationMessage={location?.state?.message}
                                disclaimer={page.disclaimer}
                                disablePip={page.disablePip}
                            />
                        </CategoryContext.Provider>
                    </Analytics.PageWrapper>
                </StorageContext.Provider>
            </PageContext.Provider>
        </LocaleContext.Provider>
    );
};

// Removed localeSlugs here. Not needed. All locales are valid, should query categories
// instead of override pages
export const query = graphql`
    query(
        $id: String!
        $language: String!
        $marketCode: String!
        $channel: String!
        $categoryId: String = null
    ) {
        ...SiteMetadata
        page: contentfulPage(
            contentful_id: { eq: $id }
            node_locale: { eq: $language }
        ) {
            ...PageGeneral
            entry {
                ... on ContentfulMainCategory {
                    ...MainCategory
                }
                ... on ContentfulSubCategory {
                    ...SubCategory
                    maincategory {
                        slug
                        label
                    }
                }
            }
            navigationinpanellink {
                navSubnavItemChildren {
                    location {
                        ... on ContentfulCallToAction {
                            type
                            title: name
                            externalLink
                            internalLink {
                                ... on ContentfulPage {
                                    title
                                    slug
                                    __typename
                                }
                                ... on ContentfulPageLanding {
                                    title
                                    slug
                                    __typename
                                }
                                ... on ContentfulPageHome {
                                    title
                                    __typename
                                }
                                ... on ContentfulPageExperiencePage {
                                    title
                                    slug
                                    __typename
                                }
                                ... on ContentfulDocumentVersion {
                                    name
                                    slug
                                    __typename
                                }
                            }
                            requestParameters
                        }
                        ... on ContentfulPage {
                            title
                            slug
                            breadcrumb
                        }
                        ... on ContentfulPageLanding {
                            title
                            slug
                            breadcrumb
                        }
                    }
                }
            }
            initialArticles(
                language: $language
                categoryId: $categoryId
                collectionSize: 9
                marketCode: $marketCode
            )
            disclaimer {
                ...Disclaimer
            }
        }
        channel: contentfulChannel(channel: { eq: $channel }) {
            noIndex
        }
        market: contentfulMarket(
            code: { eq: $marketCode }
            node_locale: { eq: $language }
        ) {
            ...MarketGeneral
        }
        markets: allContentfulMarket(
            filter: { node_locale: { eq: $language } }
        ) {
            nodes {
                ...MarketGeneral
            }
        }
        navigation: contentfulNavigation(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Navigation
        }
        footer: contentfulFooter(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Footer
        }
    }
`;

export default withURLParameters(PageCategoryTemplate);
